const contractAddresses = {
  presale: {
    97: "0x31a01c00bB218f02646179973c74ae4d31E181BD",
    56: "0x70ed82ea4f4CE5Dc5C8ab601eAae6FFa8aDac244",
  },
  mdapp: {
    97: "0x68369BdBb69c761c7fC70Db082B896F914F2A014",
    56: "0xB3922A7BA9a52FdD13A362F9ee9392758CF2f6A0",
  },
};

export default contractAddresses;
