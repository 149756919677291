import { JsonRpcSigner } from "ethers";
import { getPresaleAddress } from "@/utils/address";
import { getPresaleContract } from "../contracts";
import { getContractCall } from ".";

export const handleReferral = async (
  userSigner: JsonRpcSigner,
  referrer: string
) => {
  const presaleContract = getPresaleContract(getPresaleAddress(), userSigner);

  const { contractCall } = getContractCall();

  try {
    const estimatedGas = await presaleContract["recordReferral"].estimateGas(
      referrer
    );

    const response = await contractCall(
      presaleContract,
      "recordReferral",
      [referrer],
      {
        gasLimit: estimatedGas,
      }
    );

    return response;
  } catch (error: any) {
    console.error("Error: ", error);

    if (error.reason) {
      throw error.reason;
    }

    if (error.code && error.code === "CALL_EXCEPTION") {
      // eslint-disable-next-line no-throw-literal
      throw "Please verify you have enough funds to cover gas and transaction fee and try again";
    }

    throw error;
  }
};
