import BigNumber from "bignumber.js";

export enum GAS_PRICE {
  default = "5",
  fast = "6",
  instant = "7",
  testnet = "10",
}

export interface Address {
  97: string;
  56: string;
}

export enum ChainId {
  MAINNET = 56,
  TESTNET = 97,
}

export interface SerializedDownline {
  address: string;
  amount?: BigNumber;
}

export interface SerializedPresaleUserData {
  isReferred: boolean;
  levelOneReferrals?: string[];
  levelTwoReferrals?: string[];
  levelThreeReferrals?: string[];
  referralEarnings: BigNumber;
  totalReferrals: number | string;
  balance?: BigNumber;
  bnbBalance?: BigNumber;
  totalPurchase: BigNumber;
  totalPurchaseValue: BigNumber;
  referralDebt: BigNumber;
  totalWithdrawnEarnings: BigNumber;
  leadershipScore: BigNumber;
  totalReferralEarnings: BigNumber;
}

export interface SerializedPresale {
  contractAddress: string;
  rate: number;
  minBuy: BigNumber;
  maxBuy: BigNumber;
  userData?: SerializedPresaleUserData;
}

export interface IAirdropData {
  id: number;
  public_address: string;
  twitter_handle: string;
  telegram_handle: string;
  email_address: string;
  is_verified: boolean;
}
