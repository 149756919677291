export function ShortIntroVideo() {
  return (
    <VideoWrapper>
      <iframe
        className="w-full h-full rounded-xl"
        src="https://www.youtube.com/embed/rNcK1TcUnhM?rel=0"
        title="Memedapp short intro Video"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </VideoWrapper>
  );
}

export function IntroVideo() {
  return (
    <VideoWrapper>
      <iframe
        className="w-full h-full rounded-xl"
        src="https://www.youtube.com/embed/QjOLK49brXo?rel=0"
        title="Memedapp Intro video"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </VideoWrapper>
  );
}

function VideoWrapper({ children }: { children: React.ReactNode }) {
  return (
    <div className="px-5 sm:px-10 w-full">
      <div className="w-full rounded-2xl h-[200px] sm:h-[536px] border-secondary-base border-4">
        {children}
      </div>
    </div>
  );
}
