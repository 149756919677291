import { Button } from "@/components/ui/landing-page/button";
import ConnectWallet from "@/components/cta/ConnectWalletButton";
import { useWeb3Context } from "@/context/Web3Context";
import { useNavigate, useLocation } from "react-router-dom";

export const navLinks = [
  {
    path: "/",
    title: "Home",
  },
  {
    path: "/#how",
    title: "How It Works",
  },
  {
    path: "/#token",
    title: "Tokenomics",
  },
  {
    path: "/#roadmap",
    title: "Roadmap",
  },
];

export function Header() {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const web3Context = useWeb3Context();

  return (
    <header className="fixed top-5 rounded-full w-[90%] sm:w-[95%] left-0 right-0 mx-auto bg-white/10 border border-white/5 p-1 flex items-center justify-between backdrop-blur-md z-50">
      <div className="flex items-center space-x-2 pl-3">
        <img src="/logo.svg" alt="MemeDapp Logo" className="w-5 h-5" />
        <h1 className="text-sm font-semibold hidden sm:block">MemeDapps</h1>
      </div>

      <nav className="hidden sm:flex items-center space-x-4">
        {navLinks.map((link, index) => (
          <a className="text-sm font-medium" href={link.path} key={index}>
            {link.title}
          </a>
        ))}
      </nav>

      {!web3Context.isConnected && (
        <ConnectWallet>
          <Button className="rounded-full">Connect Wallet</Button>
        </ConnectWallet>
      )}
      {web3Context.isConnected && (
        <Button onClick={() => navigate("/dashboard")}>View Dashboard</Button>
      )}
    </header>
  );
}
