import {
  BrowserProvider,
  Eip1193Provider,
  JsonRpcSigner,
  isAddress,
} from "ethers";

import { signingMessage } from "../utils";
import Http from "./http";
import { JWT_KEY } from "../constants";
import { handleReferral } from "./calls/handleReferral";
import { SerializedPresaleUserData } from "@/types";

export const onSignMessage = async (
  walletProvider: Eip1193Provider | undefined
): Promise<
  | {
      signature: string;
      message: string;
    }
  | undefined
> => {
  if (!walletProvider) return;
  try {
    const provider = new BrowserProvider(walletProvider);
    const signer = await provider.getSigner();
    const message = signingMessage();
    const signature = await signer?.signMessage(message);
    return { signature, message };
  } catch (err) {
    console.log("Error: ", err);
  }
};

const fetchWithJwtClaiming = async (
  payload: { signature: string; message: string; address: string },
  url: string
) => {
  try {
    const result = await new Http().post(url, payload);
    return result["access_token"];
  } catch (error) {
    console.error("Error in fetchWithJwtClaiming:", error);
    throw error;
  }
};

export const handleJwtClaiming = async (
  walletProvider: Eip1193Provider | undefined,
  address: string,
  errorToast: (message: string) => void,
  referrer = null
) => {
  const response = await onSignMessage(walletProvider);
  if (!response) return;

  const payload = {
    signature: response.signature,
    message: response.message,
    address: address ?? "",
  };

  if (referrer) {
    payload[referrer] = referrer;
  }

  try {
    const apiUrl = `/users/login`;
    const jwtToken = await fetchWithJwtClaiming(payload, apiUrl);
    localStorage.setItem(JWT_KEY, jwtToken);
  } catch (err: any) {
    errorToast(err);
  }
};

export const handleVerifyReferral = async (
  userData: SerializedPresaleUserData,
  userSigner: JsonRpcSigner,
  errorToast: (message: string) => void,
  successToast: (message: string) => void,
  txToast: (message: string) => void,
  referrer = null
) => {
  if (!userData.isReferred) {
    if (!referrer) {
      errorToast("You need to be referred by someone to access the platform");
      return;
    }

    if (!isAddress(referrer)) {
      errorToast("The referral link is not valid!");
      return;
    }

    try {
      const tx = await handleReferral(userSigner, referrer);

      successToast("Recording referral. Pending confirmation...");

      const receipt = await tx.wait();
      txToast(receipt.hash);
      window.location.reload();
    } catch (err: any) {
      console.log(err);
      errorToast(err);
      return;
    }
  }

  return userData;
};
