const AmountInput = (props) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const handleChange = (e) => {
    props.onChange(Number(e.target.value));
  };

  return (
    <input type="number" onChange={handleChange} className="w-full outline-none border-none font-medium"/>
  );
};

export default AmountInput;
