import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { cn } from "@/lib/utils";
import BigNumber from "bignumber.js";
import { useWeb3Context } from "@/context/Web3Context";
import {
  getUserDownlines,
  fetchUserDetails,
} from "@/helpers/calls/fetchUserData";
import { SerializedDownline } from "@/types";
import { getFormattedBalance, getFullDisplayBalance } from "@/helpers/formats";

export function ReferralEarnings() {
  const [tierLevel, setTierLevel] = useState<number>(1);
  const { address } = useWeb3Context();
  const [levelDownlines, setLevelDownlines] = useState<SerializedDownline[]>(
    []
  );

  const getLevelPercentage = (amount: BigNumber) => {
    const percentages = [5, 3, 2];
    const levelPercentage = percentages[tierLevel - 1];

    const fullAmount = getFullDisplayBalance(amount);

    return getFormattedBalance((Number(fullAmount) * levelPercentage) / 100);
  };

  const getDownlines = async () => {
    setLevelDownlines([]);
    const downlines = await getUserDownlines(address, tierLevel);
    setLevelDownlines(downlines);

    downlines.map(async (downline) => {
      const userData = await fetchUserDetails(downline.address);
      const index = downlines.findIndex(
        (el) => el.address === downline.address
      );
      const newDownlines = [...downlines];
      newDownlines[index].amount = userData.totalPurchaseValue;

      setLevelDownlines([...newDownlines]);
    });
  };

  useEffect(() => {
    if (address) {
      getDownlines();
    }
  }, [tierLevel, address]);

  return (
    <div className="w-full 2xl:max-w-full">
      <h1 className="text-xl text-background font-extrabold pb-5">
        Referral Earnings
      </h1>
      <div className="w-full flex items-center">
        {[1, 2, 3].map((tier, index) => (
          <Tier
            tier={tier}
            key={index}
            tierLevel={tierLevel}
            setTierLevel={setTierLevel}
          />
        ))}
      </div>
      <div>
        {levelDownlines.length > 0 ? (
          levelDownlines.map((downline) => (
            <Earning
              address={downline.address}
              amountReceived={
                downline.amount ? getLevelPercentage(downline.amount) : "__"
              }
            />
          ))
        ) : (
          <div style={{textAlign: "center", marginTop: "10px"}}>You have no active downline yet.</div>
        )}
        {}
      </div>
    </div>
  );
}

function Earning({
  amountReceived,
  address,
  className,
}: {
  amountReceived: number | string;
  address: string;
  className?: string;
}) {
  return (
    <div
      className={cn(
        `flex shadow w-full justify-between items-start p-4 ${className}`
      )}
    >
      <div>
        <h6 className="text-sm font-bold">Referral Address</h6>
        <h5 className="text-black/50 font-semibold">{address}</h5>
        <span className="text-xs text-[#1B882D] font-bold">
          Amount Received: {amountReceived} BNB
        </span>
      </div>
    </div>
  );
}

function Tier({
  tier,
  tierLevel,
  setTierLevel,
}: {
  tier: number;
  tierLevel: number;
  setTierLevel: Dispatch<SetStateAction<number>>;
}) {
  return (
    <button
      className={cn(
        `w-full font-bold  py-3 ${
          tier === tierLevel
            ? "text-white bg-secondary"
            : "text-black/30 bg-secondary/50"
        } ${tier === 1 && "rounded-tl-2xl"} ${
          tier === 3 && "rounded-tr-2xl transition-all"
        }`
      )}
      onClick={() => {
        setTierLevel(tier);
      }}
    >
      Level {tier} ({tier === 1 ? "5%" : tier === 2 ? "3%" : tier === 3 ? "2%" : ""})
    </button>
  );
}
