import { Button, buttonVariants } from "@/components/ui/landing-page/button";
import { cn } from "@/lib/utils";

const firstRow = [
  {
    title: "1. Fund Wallet",
    description:
      "Fund your MetaMask or Trustwallet with a minimum of $10 in bnb",
  },
  {
    title: "2. Copy Link",
    description: "Copy a referral link",
  },
];

const secondRow = [
  {
    title: "3. Paste Link",
    description:
      "Paste on your Dapp browser and connect to the website https://memedapp.io",
  },
  {
    title: "4. BNB Amount",
    description: "Input the amount in BNB",
  },
  {
    title: "5. Approve",
    description: "Approve transaction",
  },
];

export function Steps() {
  return (
    <div className="py-20 flex items-center flex-col space-y-5 w-full px-5 md:px-10">
      <div className="flex sm:items-center space-y-3 flex-col w-full">
        <div style={{ display: "inline-block", maxWidth: "fit-content" }}>
          <span className="px-4 py-2 rounded-full bg-secondary-nase/15 border border-secondary-base text-xs font-medium text-secondary-base bg-secondary-base/20">
            Hatching the Next Generation of Meme Tokens
          </span>
        </div>
        <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold bg-gradient-to-br from-white to-white/10  inline-block text-transparent bg-clip-text">
          How To Get MDapp
        </h1>
        <a
          href="/whitepaper.pdf"
          download
          className={cn(
            buttonVariants({
              variant: "outline",
              className: "bg-white/20",
            })
          )}
        >
          Download Whitepaper
        </a>
      </div>

      <div className="space-y-5 w-full">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 w-full">
          {firstRow.map((item, index) => (
            <Step {...item} key={index} />
          ))}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 w-full">
          {secondRow.map((item, index) => (
            <Step {...item} key={index} />
          ))}
        </div>
      </div>

      <Details />
    </div>
  );
}

function Step({ title, description }: { title: string; description: string }) {
  return (
    <div className="border border-white/5 bg-gradient-to-br from-white/10 to-secondary-base/15 flex flex-col items-center text-center p-10 rounded-xl w-full">
      <h2 className="text-2xl font-semibold  bg-gradient-to-br from-white to-white/40 inline-block text-transparent bg-clip-text">
        {title}
      </h2>
      <p className="max-w-xs font-medium text-white/80">{description}</p>
    </div>
  );
}

function Details() {
  return (
    <div className="grid  md:grid-rows-3 md:grid-flow-col gap-4">
      <div className="md:row-span-3 flex flex-col space-y-3 text-white/80 bg-white/10 border border-white/5 p-5 rounded-xl font-medium">
        <h1 className="bg-gradient-to-br from-secondary-base to-white/5 inline-block text-transparent bg-clip-text text-2xl font-bold">
          Refer To Earn
        </h1>
        <p>
          Spread the fun and earn rewards! Refer your friends to MemeDApp and
          get rewarded with $BNB for each successful referral.
        </p>
        <p>
          <span className="text-lg font-semibold bg-gradient-to-br from-white to-white/40 inline-block text-transparent bg-clip-text">
            Tier 1
          </span>{" "}
          <br /> 5% referral commission for users who directly refer new users
          to the platform. <br /> <br />{" "}
          <span className="text-lg font-semibold bg-gradient-to-br from-white to-white/40 inline-block text-transparent bg-clip-text">
            Tier 2
          </span>{" "}
          <br /> 3% referral commission for users who are referred by a Tier 1
          user (indirect referral). <br /> <br />{" "}
          <span className="text-lg font-semibold bg-gradient-to-br from-white to-white/40 inline-block text-transparent bg-clip-text">
            Tier 3
          </span>{" "}
          <br /> 2% referral commission for users who are referred by a Tier 2
          user (indirect referral).
        </p>
      </div>
      <div className="md:col-span-2 flex flex-col space-y-3 text-white/80 bg-white/10 border border-white/5 p-5 rounded-xl font-medium">
        <h1 className="bg-gradient-to-br from-secondary-base to-white/5 inline-block text-transparent bg-clip-text text-2xl font-bold">
          Airdrop
        </h1>
        <p>
          Get started with MemeDApp for free! Claim your $MDapp tokens through
          our airdrop program. Spots are limited. Join our REFER AND EARN
          presale campaign &amp; Earn up to 3 Tiers (5% , 3% and 2% ) in $BNB
          Instant earnings and withdrawals
        </p>
      </div>
      <div className="md:row-span-2 md:col-span-2 flex flex-col space-y-3 text-white/80 bg-white/10 border border-white/5 p-5 rounded-xl font-medium">
        <h1 className="bg-gradient-to-br from-secondary-base to-white/5 inline-block text-transparent bg-clip-text text-2xl font-bold">
          Presale
        </h1>
        <p>
          Want to be a part of the exclusive MemeDApp community and secure
          $MDapp tokens early ? Participate in our limited-time presale!
        </p>
        <div>
          {" "}
          <h4 className="text-lg font-semibold bg-gradient-to-br from-white to-white/40 inline-block text-transparent bg-clip-text">
            Presale Features
          </h4>
          <ul className="list-disc pl-4">
            <li>
              Min buy $10 (of <span className="text-primary-base">BNB</span>)
            </li>
            <li>
              {" "}
              Max buy 10,000 (of <span className="text-primary-base">BNB</span>)
            </li>
            <li>100% liquidity lock</li>
            <li>Cex listing</li>
            <li>No team token</li>
          </ul>
        </div>
        <a
          href="https://bscscan.com/address/0xB3922A7BA9a52FdD13A362F9ee9392758CF2f6A0#code"
          target="_blank"
          className={cn(buttonVariants({ variant: "outline" }))}
        >
          {" "}
          Token Contract
        </a>{" "}
      </div>
    </div>
  );
}
