import { Box } from "@chakra-ui/react";
import { Header } from "../dashboard/header";
import Pagination from "@/components/ui/Pagination";
import { useState } from "react";
import UsersTable from "./UsersTable";

export default function Dashboard() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItem, setTotalItem] = useState(0);

  return (
    <div className="w-full bg-white lg:h-screen lg:overflow-y-scroll pb-10">
      <Header />
      <div className="lg:px-10 space-y-10 px-5">
        <UsersTable
          setLimit={setLimit}
          setPage={setPage}
          setTotal={setTotalItem}
          limit={limit}
          page={page}
        />
        <Box mt={"auto"} pt={"12"}>
          <Pagination
            setLimit={setLimit}
            setPage={setPage}
            setTotal={setTotalItem}
            limit={limit}
            page={page}
            totalItem={totalItem}
          />
        </Box>
      </div>
    </div>
  );
}
