import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import Http from "../../helpers/http";
import { onSignMessage } from "@/helpers/user";
import { useWeb3Context } from "@/context/Web3Context";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { useCustomToast } from "@/helpers/useToast";
import { useState } from "react";

export function TasksDialog() {
  const [email, setEmail] = useState(null);
  const [twitterHandle, setTwitterHandle] = useState(null);
  const [telegramHandle, setTelegramHandle] = useState(null);
  const [pendingTx, setTxStatus] = useState<boolean>(false);

  const { errorToast, successToast } = useCustomToast();
  const { walletProvider } = useWeb3ModalProvider();
  const { address } = useWeb3Context();

  const submit = async () => {
    if (!address || !walletProvider) {
      return;
    }

    if (!email || !twitterHandle || !telegramHandle) {
      errorToast("Please fill in the form");
      return;
    }
    setTxStatus(true);

    const response = await onSignMessage(walletProvider);
    if (!response) return;

    try {
      const payload = {
        signature: response.signature,
        message: response.message,
        address: address,
        twitter_handle: twitterHandle,
        telegram_handle: telegramHandle,
        email_address: email,
      };

      const url = `/users/join-airdrop`;
      await new Http().post(url, payload);

      successToast("Request submitted.");
      setTxStatus(false);

      setTimeout(() => {
        window.location.reload();
      }, 400);
    } catch (err: any) {
      errorToast(err);
      setTxStatus(false);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="w-full mt-5 rounded-full" variant={"dialog"}>
          Completed
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            <div className="text-2xl lg:text-3xl font-bold relative flex flex-col text-center">
              <span className="relative z-10">Tasks</span>
              <span className="dark-text-outline absolute left-1/2 transform  -translate-x-[49%] translate-y-[9%] whitespace-nowrap">
                Tasks
              </span>
            </div>
          </DialogTitle>
          <DialogDescription className="text-center text-[10px] text-background">
            Please enter the appropriate information
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-5">
          <input
            type="email"
            className="text-background placeholder:text-background text-sm font-medium bg-black/5 w-full rounded-md p-3"
            placeholder="Enter Your Email Address"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="text"
            className="text-background placeholder:text-background text-sm font-medium bg-black/5 w-full rounded-md p-3"
            placeholder="Enter Twitter Username you followed with"
            onChange={(e) => setTwitterHandle(e.target.value)}
          />
          <input
            type="text"
            className="text-background placeholder:text-background text-sm font-medium bg-black/5 w-full rounded-md p-3"
            placeholder="Enter Telegram Username"
            onChange={(e) => setTelegramHandle(e.target.value)}
          />
        </div>
        <DialogFooter className="pt-5">
          <Button
            disabled={pendingTx}
            variant={"dialog"}
            size={"xxl"}
            className="w-full rounded-full"
            onClick={submit}
          >
            {pendingTx ? "Confirming..." : "Confirm"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
