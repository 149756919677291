import { Button } from "@/components/ui/landing-page/button";
import tokens from "@/assets/tokens.svg";
import hero from "@/assets/hero.png";
import { useWeb3Context } from "@/context/Web3Context";
import { useNavigate } from "react-router-dom";
import ConnectWallet from "@/components/cta/ConnectWalletButton";
import { useState, useEffect } from "react";

export function Hero() {
  const web3Context = useWeb3Context();
  const navigate = useNavigate();

  return (
    <div
      className="bg-cover flex flex-col space-y-5 lg:space-y-0  lg:flex-row justify-between items-center h-[100vh] px-5 md:px-10 pt-24 lg:pt-0"
      id="home"
      style={{
        background: "url('/hero-bg.svg')",
      }}
    >
      <div className="flex flex-col space-y-4">
        <h1 className="text-3xl sm:text-4xl lg:text-5xl font-black bg-gradient-to-br from-white to-white/40 inline-block text-transparent bg-clip-text">
          Join the MemeDapp Revolution!
        </h1>
        <p className="text-white/70 font-medium max-w-2xl">
          MemeDapp offers a unique ecosystem for creators, artists, and
          investors to collaborate, innovate, and propel the next generation of
          meme tokens. Whether you're a budding meme creator with a hilarious
          idea or an investor seeking the next big thing, MemeDapp provides the
          tools and resources to make your meme dreams a reality.
        </p>
        <div className="w-full flex items-start">
          <img src={tokens} alt="" className="h-10" />
        </div>
        <div className="space-x-2 flex items-center">
          {!web3Context.isConnected && (
            <ConnectWallet>
              <Button>Join Presale</Button>
            </ConnectWallet>
          )}
          {web3Context.isConnected && (
            <Button onClick={() => navigate("/dashboard")}>Join Presale</Button>
          )}

          <Button variant={"outline"} asChild>
            <a href="https://cyberscope.io/audits/mdapp" target="_blank">
              Audit Report
            </a>
          </Button>
        </div>
     
      </div>
      <div>
        <img src={hero} alt="" />
      </div>
    </div>
  );
}



