import one from "@/assets/carousel/binance.svg";
import two from "@/assets/carousel/dapp.png";
import three from "@/assets/carousel/gate.png";
import four from "@/assets/carousel/pancake.png";
import five from "@/assets/carousel/pocket.png";
import six from "@/assets/carousel/radar.png";

const slides = [one, two, three, four, five, six];

export function Exchanges() {
  return (
    <div className="w-full">
      <div className="px-5 md:px-10 sm:text-center">
        <h1 className="text-2xl sm:text-3xl font-bold bg-gradient-to-br from-white to-white/10  inline-block text-transparent bg-clip-text">
          Exchanges To Be Listed On
        </h1>
      </div>
      <div className="w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]">
        <Slides hidden={false} />
        <Slides hidden={true} />
      </div>
    </div>
  );
}

function Slides({ hidden = true }: { hidden: boolean }) {
  return (
    <ul
      className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll"
      aria-hidden={`${hidden}`}
    >
      {slides.map((slide, index) => (
        <li key={index}>
          <img src={slide} alt="Carousel Slide" key={index} className="w-28" />
        </li>
      ))}
    </ul>
  );
}
