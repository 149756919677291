import { TransactionResponse } from "ethers";
import BigNumber from "bignumber.js";
import { getContractCall } from "./";
import { usePresaleContract } from "../useContract";
import { BIG_TEN } from "@/constants";

interface WithdrawEarningsProps {
    withdrawalAmount: number;
}

export function useWithdrawEarningCallback(): {
  withdrawEarnings: (props: WithdrawEarningsProps) => Promise<TransactionResponse>;
} {
  const { contractCall } = getContractCall();

  const presaleContract = usePresaleContract();

  const withdrawEarnings = async (
    props: WithdrawEarningsProps
  ): Promise<TransactionResponse> => {
    const amount = new BigNumber(props.withdrawalAmount)
      .times(BIG_TEN.pow(18))
      .toString(10);

    if (!presaleContract) {
      console.error("tokenContract is null");
      return;
    }

    const functionName = "withdrawEarnings";

    try {
      const estimatedGas = await presaleContract[functionName].estimateGas(amount);

      const response = await contractCall(presaleContract, functionName, [amount], {
        gasLimit: estimatedGas,
      });

      return response;
    } catch (error: any) {
      console.error("Error: ", error);

      if (error.reason) {
        throw error.reason;
      }

      if (error.code && error.code === "CALL_EXCEPTION") {
        // eslint-disable-next-line no-throw-literal
        throw "Please verify you have enough funds to cover gas and transaction fee and try again";
      }

      throw error;
    }
  };

  return { withdrawEarnings };
}
