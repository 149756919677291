import { buttonVariants } from "@/components/ui/button";
import { CheckCircle, ChevronDown, ChevronUp } from "lucide-react";
import { cn } from "@/lib/utils";
import { TasksDialog } from "@/components/dialogs/tasks";
import { IAirdropData } from "@/types";
import { useEffect, useState } from "react";
import { useWeb3Context } from "@/context/Web3Context";
import Http from "@/helpers/http";
import { motion, AnimatePresence } from "framer-motion";

const tasks = [
  {
    task: "Follow MemeDapp on X",
    cta: (
      <div className="mt-3 w-full">
        <a
          href="https://x.com/memedapp?t=H66akWovBpGK9vO1SxAckg&s=09"
          className={cn(
            buttonVariants({
              variant: "link",
              size: "sm",
              className: "bg-[#468FE5] w-full rounded-full py-2",
            })
          )}
        >
          Follow Us on twitter
        </a>
      </div>
    ),
  },
  {
    task: "Join Our Telegram channel",
    cta: (
      <div className="mt-3 w-full">
        <a
          href="https://t.me/official_memedapp"
          className={cn(
            buttonVariants({
              variant: "link",
              size: "sm",
              className: "bg-[#215488] w-full rounded-full py-2",
            })
          )}
        >
          Join Telegram Channel
        </a>
      </div>
    ),
  },
  {
    task: "If the above are done click proceed to complete final task",
    cta: (
      <div>
        <TasksDialog />
      </div>
    ),
  },
];

export function Tasks({airdropData, setAirdropData} : {airdropData: IAirdropData; setAirdropData: (data: IAirdropData) => void}) {
  const [isLoading, setLoader] = useState(true);
  const [isOpen, setIsOpen] = useState(true);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const { address } = useWeb3Context();

  const getAirdrop = async () => {
    const url = `/users/airdrops/${address}`;
    const result: any = await new Http().get(url);
    setAirdropData(result);
    setLoader(false);
  };

  useEffect(() => {
    getAirdrop();
  }, []);

  if (isLoading || (!isLoading && airdropData)) {
    return <></>;
  }

  return (
    <div className="w-full 2xl:max-w-full">
      <button
        onClick={toggleMenu}
        className="text-xl text-background font-extrabold pb-5 flex items-center w-full justify-between"
      >
        <span>Airdrop tasks</span>
        {isOpen ? <ChevronUp /> : <ChevronDown />}
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="space-y-5 w-full"
          >
            {tasks.map((task, index) => (
              <Task done={false} key={index} index={index} task={task.task}>
                {task.cta}
              </Task>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

function Task({
  children,
  index,
  task,
  done,
}: {
  children: React.ReactNode;
  index: number;
  task: string;
  done: boolean;
}) {
  return (
    <div className="shadow-md w-full min-h-[125px] rounded-2xl pb-3 relative">
      {done && (
        <CheckCircle className="fill-green-500 stroke-white absolute top-3 right-4" />
      )}
      <div className="text-white bg-background px-5 py-1.5 shadow-md rounded-b-2xl w-40 text-center mx-auto">
        <h4 className="text-sm font-bold">Task #{index + 1}</h4>
      </div>
      <div className="px-10 text-center w-full">
        <p className="font-semibold py-1">{task}</p>
        {children}
      </div>
    </div>
  );
}
