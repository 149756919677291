import Index from "./index";
import Dashboard from "./dashboard";
import ManageAirdrops from "./ManageAirdrop";


export const routes = [
  {
    path: "/",
    name: "Index",
    private: false,
    component: Index,
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    private: false,
    component: Dashboard,
  },
  {
    name: "ManageAirdrops",
    path: "/dashboard/manage-airdrops",
    private: false,
    component: ManageAirdrops,
  },
];
