import { Header } from "@/components/container/landing-page/header";
import { Hero } from "@/components/container/landing-page/hero";
import { Steps } from "@/components/container/landing-page/how-to";
import { Roadmap } from "@/components/container/landing-page/roadmap";
import { Tokenomics } from "@/components/container/landing-page/tokenomics";
import { Footer } from "@/components/container/landing-page/footer";
import {
  ShortIntroVideo,
  IntroVideo,
} from "@/components/container/landing-page/promotional-video";
import { Exchanges } from "@/components/container/landing-page/exchanges";

export default function Index() {
  return (
    <main className="overfloe-x-hidden bg-black text-white">
      <Header />
      <Hero />
      <div className="mt-7 lg:mt-0" />
      <IntroVideo />
      <div className="py-7 sm:py-0" id="how" />
      <Steps />
      <div className="py-7 sm:py-16" id="roadmap" />
      <Roadmap />
      <div className="py-7 sm:py-16" id="token" />
      <Tokenomics />
      <div className="py-7 sm:py-16" />
      <ShortIntroVideo />
      <div className="py-7 sm:py-16" />
      <Exchanges />
      <Footer />
    </main>
  );
}
