import { Sidebar } from "@/components/container/dashboard/sidebar";
import ManageDashboardContent from "@/components/container/manage-airdrops";
import { Toaster } from "react-hot-toast";

export default function ManageAirdrop() {
  return (
    <main className="bg-[#F2F8FF] w-full min-h-screen lg:px-10 flex flex-col lg:flex-row items-center justify-between lg:space-x-10 overflow-x-hidden">
      <Toaster />
      <Sidebar />
      <ManageDashboardContent />
    </main>
  );
}
