import { SerializedPresale } from "@/types";
import { getPresaleAddress } from "@/utils/address";
import BigNumber from "bignumber.js";

const presaleConfig: SerializedPresale = {
  contractAddress: getPresaleAddress(),
  rate: 11999.999999999998,
  minBuy: new BigNumber(0.01666),
  maxBuy: new BigNumber(16.66),
};

export default presaleConfig;
