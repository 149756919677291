import { Box, Flex, Select, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

export function ArrowIcon() {
  return (
    <svg
      width="37"
      height="36"
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="0.5" width="35" height="35" rx="17.5" fill="#BDBDBD" />
      <rect x="1" y="0.5" width="35" height="35" rx="17.5" stroke="#F0F2F5" />
      <path
        d="M15.0973 19.1424C14.4933 18.5007 14.4933 17.4996 15.0973 16.8579L21.2264 10.3457C21.5418 10.0105 22.0692 9.99457 22.4044 10.31C22.7395 10.6254 22.7555 11.1528 22.4401 11.488L16.311 18.0002L22.4401 24.5124C22.7555 24.8475 22.7395 25.3749 22.4044 25.6903C22.0692 26.0058 21.5418 25.9898 21.2264 25.6546L15.0973 19.1424Z"
        fill="#010203"
      />
    </svg>
  );
}

interface Props {
  page: number;
  setPage: (by: number) => void;
  limit: number;
  setLimit: (by: number) => void;
  totalItem: number;
  setTotal: (by: number) => void;
}

function Pagination(props: Props) {
  const { limit, page, setPage, setLimit, totalItem } = props;

  const [totaldata, setTotaldata] = useState(1);

  useEffect(() => {
    const value = totalItem / limit;

    if (Number(value.toString().split(".")[1]) > 0) {
      // if()
      setTotaldata(Number(value.toString().split(".")[0]) + 1);
    } else {
      setTotaldata(Number(value.toString().split(".")[0]));
    }
  }, [totalItem, limit]);

  return (
    <Flex w={"full"} flexDir={"column"} pb={"3"}>
      <Flex
        position={"relative"}
        w={"full"}
        height={"40px"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {totaldata > 1 && (
          <Flex zIndex={"10"} w={"fit-content"} gap={"2"}>
            <Box
              disabled={page === 1 ? true : false}
              cursor={page === 1 ? "not-allowed" : "pointer"}
              onClick={() => setPage(page - 1)}
              as="button"
            >
              <ArrowIcon />
            </Box>
            {new Array(totaldata).fill("").map((_, i) => {
              if (i + 1 <= 3) {
                return (
                  <Flex
                    key={i}
                    fontSize={"12px"}
                    border={i + 1 === page ? "1px solid #BDBDBD" : ""}
                    bgColor={i + 1 === page ? "#F2F2F2" : ""}
                    justifyContent={"center"}
                    alignItems={"center"}
                    width={"35px"}
                    height={"35px"}
                    rounded={"full"}
                  >
                    {i + 1}
                  </Flex>
                );
              } else if (i === 3) {
                return (
                  <Flex w={"fit-content"} gap={"2"}>
                    <Text>...</Text>
                    {(i + 1 === page ||
                      totaldata - 1 === 4 ||
                      totaldata === 4) && (
                      <Flex
                        key={i}
                        fontSize={"12px"}
                        border={i + 1 === page ? "1px solid #BDBDBD" : ""}
                        bgColor={i + 1 === page ? "#F2F2F2" : ""}
                        justifyContent={"center"}
                        alignItems={"center"}
                        width={"35px"}
                        height={"35px"}
                        rounded={"full"}
                      >
                        {i + 1}
                      </Flex>
                    )}
                    {i + 1 === page &&
                      page <= 4 &&
                      totaldata - 1 !== 4 &&
                      totaldata !== 4 && <Text>...</Text>}
                  </Flex>
                );
              } else if (
                i > 2 &&
                i + 1 === page &&
                i + 1 !== totaldata - 1 &&
                i + 1 !== totaldata
              ) {
                console.log("gello");
                return (
                  <Flex w={"fit-content"} gap={"2"}>
                    <Flex
                      key={i}
                      fontSize={"12px"}
                      border={i + 1 === page ? "1px solid #BDBDBD" : ""}
                      bgColor={i + 1 === page ? "#F2F2F2" : ""}
                      justifyContent={"center"}
                      alignItems={"center"}
                      width={"35px"}
                      height={"35px"}
                      rounded={"full"}
                    >
                      {i + 1}
                    </Flex>

                    <Text>...</Text>
                  </Flex>
                );
              } else if (i + 1 === totaldata - 1 && i !== 2) {
                return (
                  <Flex w={"fit-content"} gap={"2"}>
                    <Flex
                      key={i}
                      fontSize={"12px"}
                      border={i + 1 === page ? "1px solid #BDBDBD" : ""}
                      bgColor={i + 1 === page ? "#F2F2F2" : ""}
                      justifyContent={"center"}
                      alignItems={"center"}
                      width={"35px"}
                      height={"35px"}
                      rounded={"full"}
                    >
                      {i + 1}
                    </Flex>
                  </Flex>
                );
              } else if (i + 1 === totaldata && i !== 2) {
                return (
                  <Flex w={"fit-content"} gap={"2"}>
                    <Flex
                      key={i}
                      fontSize={"12px"}
                      border={i + 1 === page ? "1px solid #BDBDBD" : ""}
                      bgColor={i + 1 === page ? "#F2F2F2" : ""}
                      justifyContent={"center"}
                      alignItems={"center"}
                      width={"35px"}
                      height={"35px"}
                      rounded={"full"}
                    >
                      {i + 1}
                    </Flex>
                  </Flex>
                );
              }
            })}
            <Box
              onClick={() => setPage(page + 1)}
              as="button"
              cursor={totaldata === page ? "not-allowed" : "pointer"}
              disabled={totaldata === page ? true : false}
              transform={"rotate(180deg)"}
            >
              <ArrowIcon />
            </Box>
          </Flex>
        )}
        <Flex
          width={"full"}
          position={"absolute"}
          gap={"2"}
          justifyContent={"end"}
          alignItems={"center"}
          right={"0px"}
        >
          <Text color={"#333"} lineHeight={"20.3px"} fontSize={"14px"}>
            Records Per Page
          </Text>
          <Select
            defaultValue={limit}
            onChange={(e) => setLimit(Number(e.target.value))}
            bgColor={"white"}
            width={"fit-content"}
            border={"1.12px solid #D0D5DD"}
            rounded={"6.75px"}
          >
            <option>10</option>
            <option>20</option>
            <option>30</option>
            <option>40</option>
            <option>50</option>
            <option>60</option>
            <option>70</option>
          </Select>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Pagination;
