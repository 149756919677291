import { useWeb3Context } from "../context/Web3Context";
import { getBep20Contract, getPresaleContract } from "./contracts";
import { getPresaleAddress } from "@/utils/address";

export const useERC20 = (address: string) => {
  const { userSigner } = useWeb3Context();
  return getBep20Contract(address, userSigner);
};

export const usePresaleContract = () => {
  const { userSigner } = useWeb3Context();
  return getPresaleContract(getPresaleAddress(), userSigner);
};
