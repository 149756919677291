import { Flex, Spinner, Text } from "@chakra-ui/react";
import React from "react";

interface Props {
  loading: boolean;
  children: React.ReactNode;
  dataLength?: number;
  color?: string;
}

function LoadingAnimation(props: Props) {
  const { children, loading, dataLength, color } = props;

  if (loading) {
    return (
      <Flex width={"full"} justifyContent={"center"} fontSize={"20px"} py={"8"}>
        <Spinner size={["md", "sm"]} color={color ? color : "black"} />
      </Flex>
    );
  } else if (!loading && dataLength < 1) {
    return (
      <Flex width={"full"} justifyContent={"center"} fontSize={"20px"} py={"4"}>
        <Text>No Records Found</Text>
      </Flex>
    );
  }

  return <>{children}</>;
}

export default LoadingAnimation;
