import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { SetStateAction, useEffect, useState } from "react";
import { BrowserProvider, JsonRpcSigner } from "ethers";
import {
  createWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import Error404 from "./components/misc/Error404";
import { ETHERS_CONFIG, WALLET_CONNECT_ID } from "./constants";
import { BASE_CHAINS_LIST, CHAIN_ID } from "./constants/network";
import { Web3ContextProvider } from "./context/Web3Context";
import { MiscContextProvider } from "./context/MiscContextProvider";
import { routes } from "./routes/routes";

const projectId = WALLET_CONNECT_ID;

createWeb3Modal({
  defaultChain: BASE_CHAINS_LIST[CHAIN_ID],
  ethersConfig: ETHERS_CONFIG,
  chains: BASE_CHAINS_LIST,
  projectId,
});

function App() {
  const { walletProvider } = useWeb3ModalProvider();
  const { chainId, isConnected } = useWeb3ModalAccount();
  const [userSigner, setUserSigner] = useState<JsonRpcSigner | null>(null);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        {routes.map((route) => (
          <Route
            key={route.name}
            path={route.path}
            element={<route.component />}
          />
        ))}
        <Route path="*" element={<Error404 />} />
      </Route>
    )
  );

  const getSigner = async () => {
    if (walletProvider) {
      try {
        const ethersProvider = new BrowserProvider(walletProvider);
        const signer = await ethersProvider.getSigner();
        setUserSigner(signer as unknown as SetStateAction<JsonRpcSigner>);
      } catch (err) {
        console.log("Error: ", err);
      }
    }
  };

  useEffect(() => {
    if (isConnected && chainId === parseInt(CHAIN_ID, 10)) {
      getSigner();
    }
  }, [isConnected, chainId]);

  return (
    <Web3ContextProvider userSigner={userSigner}>
      <MiscContextProvider>
        <RouterProvider router={router} />
      </MiscContextProvider>
    </Web3ContextProvider>
  );
}

export default App;
