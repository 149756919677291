import { useContext, createContext, useEffect, useMemo, useState } from "react";
import { BrowserProvider, JsonRpcSigner } from "ethers";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { CHAIN_ID } from "../constants/network";
import { handleVerifyReferral } from "../helpers/user";
import { useCustomToast } from "@/helpers/useToast";
import { fetchUserDetails, generalUserData } from "@/helpers/calls/fetchUserData";
import { SerializedPresaleUserData } from "@/types";

interface Web3ContextType {
  chainId: number | undefined;
  isConnected: boolean;
  address: `0x${string}` | null | undefined;
  referralLink: string | null;
  userSigner: JsonRpcSigner;
  userPresaleData: SerializedPresaleUserData;
}

const Web3Context = createContext<null | Web3ContextType>(null);

export const Web3ContextProvider = ({
  children,
  userSigner,
}: {
  children: JSX.Element;
  userSigner: JsonRpcSigner;
}) => {
  const { chainId, isConnected, address } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [userPresaleData, setUserPresaleData] = useState(null);
  const { txToast, errorToast, successToast } = useCustomToast();

  const switchWalletChain = async () => {
    if (walletProvider) {
      try {
        const ethersProvider = new BrowserProvider(walletProvider);
        await ethersProvider.send("wallet_switchEthereumChain", [
          {
            chainId: `0x${parseInt(CHAIN_ID, 10).toString(16)}`,
          },
        ]);
      } catch (err) {
        console.log("Error: ", err);
      }
    }
  };

  const authenticateAddress = async () => {
    const queryParams = new URL(document.location.toString()).searchParams;
    const referrer = queryParams.get("referrer");

    const userData = await fetchUserDetails(address);
    setUserPresaleData({ ...userPresaleData, ...userData });

    await handleVerifyReferral(
      userData,
      userSigner,
      errorToast,
      successToast,
      txToast,
      referrer
    );
  };

  const getOnchainUserData = async () => {
    const userData = await generalUserData(address);
    setUserPresaleData({ ...userPresaleData, ...userData });
  }

  useEffect(() => {
    if (isConnected && chainId !== parseInt(CHAIN_ID, 10)) {
      switchWalletChain();
    }
  }, [chainId, isConnected]);

  useEffect(() => {
    if (isConnected && chainId === parseInt(CHAIN_ID, 10) && userSigner) {
      getOnchainUserData()
    }
  }, [chainId, isConnected, address, userSigner]);

  useEffect(() => {
    if (isConnected && chainId === parseInt(CHAIN_ID, 10) && userSigner) {
      authenticateAddress();
    }
  }, [chainId, isConnected, address, userSigner]);

  const web3ContextValue = useMemo(
    () => ({
      chainId,
      isConnected:
        isConnected && chainId === parseInt(CHAIN_ID, 10) ? true : false,
      address:
        isConnected && chainId === parseInt(CHAIN_ID, 10) ? address : null,
      userSigner,
      referralLink:
        isConnected && chainId === parseInt(CHAIN_ID, 10)
          ? `${window.location.protocol}//${window.location.host}?referrer=${address}`
          : null,
      userPresaleData: userPresaleData,
    }),
    [chainId, address, isConnected, userSigner, userPresaleData]
  );

  return (
    <Web3Context.Provider value={web3ContextValue}>
      {children}
    </Web3Context.Provider>
  );
};

export function useWeb3Context() {
  const context = useContext(Web3Context);

  if (!context) {
    throw new Error("useWeb3Context must be used within a Web3ContextProvider");
  }

  return context;
}
