import home from "@/assets/images/dashboard-header/home.svg";
import logout from "@/assets/images/dashboard-header/logout.svg";
import tabIndicator from "@/assets/images/dashboard-header/tab-indicator.svg";
import { useWeb3Context } from "@/context/Web3Context";
import ConnectWallet from "@/components/cta/ConnectWalletButton";
import { useDisconnect } from "@web3modal/ethers/react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export function Header() {
  const { disconnect } = useDisconnect();
  const web3Context = useWeb3Context();
  const navigate = useNavigate();

  const onLogout = async () => {
    await disconnect();

    navigate("/");
  };

  return (
    <header className="hidden lg:flex justify-between pr-10 py-8">
      <Link to="/" className="flex items-center space-x-10">
        <img src={tabIndicator} alt="Tab Indicator Icon" />
        <div className="flex items-center space-x-4">
          <img src={home} alt="Home Icon" className="w-7 h-7" />
          <span className="text-[#B8BED9] text-bold pt-0.5">Home</span>
        </div>
      </Link>

      {web3Context.isConnected ? (
        <div className="flex items-center space-x-3" onClick={onLogout}>
          <img src={logout} alt="Logout Icon" />
          <span className="text-destructive font-bold">Logout</span>
        </div>
      ) : (
        <div>
          <div className="flex items-center space-x-3 cursor-pointer">
            <span className="text-destructive font-bold">
              <ConnectWallet>
                <>Connect Wallet</>
              </ConnectWallet>
            </span>
          </div>
        </div>
      )}
    </header>
  );
}
