// import { Button } from "@/components/ui/button";
import { useWeb3Context } from "@/context/Web3Context";
import { getFullDisplayBalance } from "@/helpers/formats";
import { IAirdropData } from "@/types";

export function Portfolio({airdropData}: {airdropData: IAirdropData}) {
  const { userPresaleData } = useWeb3Context();
  return (
    <div className="space-y-5">
      <h1 className="text-xl text-background font-extrabold">My Portfolio</h1>
      {userPresaleData ? (<>
        <div className="flex items-center space-y-5 2xl:space-y-0 2xl:space-x-5 flex-col 2xl:flex-row">
        <div className="w-full flex justify-between shadow border p-5 rounded-2xl h-[125px]">
          <div className="space-x-5 flex items-center h-full">
            <img
              src="/logo.svg"
              alt="MemeDapp Logo"
              className="w-[25px] lg:w-[50px]"
            />
            <div className="flex flex-col justify-between h-full">
              <span className="font-bold">Referral Earnings</span>
            </div>
          </div>
          {userPresaleData ? (
            <div className="flex flex-col justify-between text-end">
              <span className="text-sm lg:text-base">
                {" "}
                {getFullDisplayBalance(
                  userPresaleData.referralEarnings
                )} BNB{" "}
              </span>
            </div>
          ) : null}
        </div>

        <div className="w-full flex justify-between shadow border p-5 rounded-2xl h-[125px]">
          <div className="space-x-5 flex items-center h-full">
            <img
              src="/logo.svg"
              alt="MemeDapp Logo"
              className="w-[25px] lg:w-[50px]"
            />
            <div className="flex flex-col justify-between h-full">
              <span className="font-bold">Airdrop Earnings</span>
            </div>
          </div>
          <div className="flex flex-col justify-between text-end">
            <span className="text-sm lg:text-base"> {airdropData && airdropData.is_verified ? 100 : 0} MDAPP </span>
          </div>
        </div>
      </div>

      <div className="flex items-center space-y-5 2xl:space-y-0 2xl:space-x-5 flex-col 2xl:flex-row">
        <div className="w-full flex justify-between shadow border p-5 rounded-2xl h-[125px]">
          <div className="space-x-5 flex items-center h-full">
            <img
              src="/logo.svg"
              alt="MemeDapp Logo"
              className="w-[25px] lg:w-[50px]"
            />
            <div className="flex flex-col justify-between h-full">
              <span className="font-bold">Total Referral Earnings</span>
            </div>
          </div>
          {userPresaleData ? (
            <div className="flex flex-col justify-between text-end">
              <span className="text-sm lg:text-base">
                {" "}
                {getFullDisplayBalance(
                  userPresaleData.totalReferralEarnings
                )} BNB{" "}
              </span>
            </div>
          ) : null}
        </div>

        <div className="w-full flex justify-between shadow border p-5 rounded-2xl h-[125px]">
          <div className="space-x-5 flex items-center h-full">
            <img
              src="/logo.svg"
              alt="MemeDapp Logo"
              className="w-[25px] lg:w-[50px]"
            />
            <div className="flex flex-col justify-between h-full">
              <span className="font-bold">Earnings Withdrawn</span>
            </div>
          </div>
          <div className="flex flex-col justify-between text-end">
            <span className="text-sm lg:text-base">{getFullDisplayBalance(
                  userPresaleData.totalWithdrawnEarnings
                )} BNB </span>
          </div>
        </div>
      </div>

      <div className="flex items-center space-y-5 2xl:space-y-0 2xl:space-x-5 flex-col 2xl:flex-row">
        <div className="w-full flex justify-between shadow border p-5 rounded-2xl h-[125px]">
          <div className="space-x-5 flex items-center h-full">
            <img
              src="/logo.svg"
              alt="MemeDapp Logo"
              className="w-[25px] lg:w-[50px]"
            />
            <div className="flex flex-col justify-between h-full">
              <span className="font-bold">Sales Volume</span>
            </div>
          </div>
          {userPresaleData ? (
            <div className="flex flex-col justify-between text-end">
              <span className="text-sm lg:text-base">
                {" "}
                {getFullDisplayBalance(
                  userPresaleData.leadershipScore
                )} BNB{" "}
              </span>
            </div>
          ) : null}
        </div>

        <div className="w-full flex justify-between">
          
        </div>
      </div>
      </>) : null}

      
    </div>
  );
}
