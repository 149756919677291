import { PieChart } from "@mui/x-charts/PieChart";
import { ThemeProvider, createTheme } from "@mui/material";

const MuiTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const data = [
  {
    value: 20,
    label: "Marketing, Partnerships & Operations (MPO) - 40,000,000",
    color: "#864B15",
  },
  { value: 5, label: "Airdrop - 10,000,000", color: "#FFC086" },
  { value: 20, label: "Presale - 40,000,000", color: "#EC9F57" },
  { value: 55, label: "Liquidity - 110,000,000", color: "#412810" },
];

const size = {
  width: 300,
  height: 300,
};

const tokenInfo = [
  {
    title: "Blockchain",
    info: "Binance Smart Chain",
  },
  {
    title: "Decimal point",
    info: "18",
  },
  {
    title: "Token symbol",
    info: "MDAPP",
  },
];

const tokenDetails = {
  "Token Supply": {
    amount: "200,000,000",
    percentage: "100%",
  },
  "Marketing, Partnerships & Operations (MPO)": {
    amount: "40,000,000",
    percentage: "20%",
  },
  Airdrop: { amount: "10,000,000", percentage: "5%" },
  Presale: { amount: "40,000,000", percentage: "20%" },
  Liquidity: { amount: "110,000,000", percentage: "55%" },
};

export function Tokenomics() {
  return (
    <div className="px-5 md:px-10">
      <div className="flex sm:items-center sm:text-center space-y-4 flex-col w-full">
        <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold bg-gradient-to-br from-white to-white/10  inline-block text-transparent bg-clip-text">
          MDapp Tokenomics
        </h1>
        <p className="max-w-2xl font-medium text-white/80">
          As stewards of the MemeDApp ecosystem, we are committed to fostering a
          sustainable and equitable token economy that benefits all
          participants. To this end, we have meticulously designed the
          tokenomics of the $MDapp token to ensure transparency, fairness, and
          long-term viability.
        </p>
        <div className="w-full flex flex-col sm:flex-row space-y-5 sm:space-y-0 sm:justify-around sm:items-center">
          {tokenInfo.map((info, index) => (
            <div className="text-start flex flex-col items-start" key={index}>
              <h4 className="text-lg font-bold bg-gradient-to-br from-secondary-base to-white/15  inline-block text-transparent bg-clip-text">
                {info.info}
              </h4>
              <h3 className="text-xs font-medium text-white/80">
                {info.title}
              </h3>
            </div>
          ))}
        </div>
      </div>
      <div className="tokenomics-chart w-full flex flex-col sm:flex-row">
        <ThemeProvider theme={MuiTheme}>
          <PieChart
            slotProps={{ legend: { hidden: true } }}
            series={[
              {
                data,
                innerRadius: 60,
                outerRadius: 100,
                paddingAngle: 5,
                cornerRadius: 5,
                startAngle: -360,
                endAngle: 180,
                cx: 150,
                cy: 150,
              },
            ]}
            {...size}
          />
        </ThemeProvider>
        <div className="container mx-auto px-4 py-8">
          <ul className="divide-y divide-white/40">
            {Object.entries(tokenDetails).map(([title, details]) => (
              <li key={title} className="py-2">
                <p className="text-white/80">
                  <strong className="text-primary-base/80">{title}</strong> -{" "}
                  {details.amount} - {details.percentage}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
