import { Header } from "./header";
import { Portfolio } from "./portfolio";
import { Rewards } from "./rewards";
import { ReferralEarnings } from "./referrals";
import { Tasks } from "./tasks";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { useWeb3Context } from "@/context/Web3Context";
import { useState } from "react";
import { IAirdropData } from "@/types";
import { getMDappAddress } from "@/utils/address";

export function Dashboard() {
  const { isConnected } = useWeb3Context();
  const [airdropData, setData] = useState<IAirdropData>(null);

  return (
    <div className="w-full bg-white lg:h-screen lg:overflow-y-scroll pb-10">
      <Header />
      <div className="lg:px-10 space-y-10 px-5">
        <CopyToClipboard
          text={getMDappAddress()}
          onCopy={() => {
            toast.success("Copied");
          }}
        >
          <div>Copy Contract Address: {getMDappAddress()}</div>
        </CopyToClipboard>
        <Rewards />
        <Portfolio airdropData={airdropData} />
      </div>
      <div className="lg:px-10 space-y-10 2xl:space-y-0 px-5 pt-10 flex flex-col 2xl:flex-row items-start 2xl:space-x-5">
        <ReferralEarnings />

        {isConnected ? (
          <Tasks airdropData={airdropData} setAirdropData={setData} />
        ) : null}
      </div>
    </div>
  );
}
