import avatar from "@/assets/images/nft-avatar.png";
import { Progress } from "@chakra-ui/react";
import { Button } from "@/components/ui/button";
import { PresaleDialog } from "@/components/dialogs/presale";
import { useWeb3Context } from "@/context/Web3Context";
import ConnectWallet from "@/components/cta/ConnectWalletButton";
import { getContractBalance } from "@/helpers/calls/fetchUserData";
import { useEffect, useState } from "react";
import { getBalanceAmount } from "@/helpers/formats";
import presaleConfig from "@/constants/presale";

export function Rewards() {
  const { userPresaleData } = useWeb3Context();
  const presaleTarget = 40_000_000;
  const [presaleGoal, setPresaleGoal] = useState(null);
  const [currentSold, setCurrentSold] = useState(0);

  const loadPresaleTarget = async () => {
    const contractBalance = getBalanceAmount(
      await getContractBalance()
    ).toNumber();

    const totalSold = presaleTarget - contractBalance;
    setCurrentSold(totalSold);

    const result = (totalSold / presaleTarget) * 100;
    setPresaleGoal(Number(result.toFixed(2)));
  };

  const convertionRate = (amount: number) => {
    return amount.toFixed(5);
  }

  useEffect(() => {
    loadPresaleTarget();
  }, []);

  return (
    <div className="flex flex-col 2xl:flex-row items-center 2xl:justify-between space-y-5 2xl:space-x-5">
      <div className="w-full 2xl:min-w-[550px] 2xl:max-w-full h-[230px] md:h-[265px] lg:h-[310px] bg-[#E27625] relative rounded-2xl px-5 lg:px-10 lg:pt-5 space-y-5">
        <img
          src={avatar}
          alt="Avatar Icon"
          className="absolute top-[61px] md:top-8 lg:top-[46px] right-2 sm:right-2 w-[100px] md:w-[170px] lg:w-[200px]"
        />
        <h1 className="text-white text-xl sm:text-2xl lg:text-3xl font-black md:leading-10 lg:leading-[45px]">
          Participate in <br />
          MemeDapp Presale
        </h1>
        {presaleGoal != null ? (
          <div className="w-[74%] md:w-[520px]">
            <h6 className="text-white font-semibold">PRESALE</h6>
            <Progress
              colorScheme="green"
              size="sm"
              value={presaleGoal}
              className="rounded-full"
            />
            <div className="mt-2 flex justify-between z-10">
                  <div className="text-center">
                    <small
                      ><h6 className="mb-0 text-white">
                        {convertionRate(currentSold)} MDAPP
                      </h6></small>
                  </div>
                  <div className="text-center">
                    <small
                      ><h6 className="mb-0 text-white">
                        {convertionRate(presaleTarget)} MDAPP
                      </h6></small>
                  </div>
                </div>
          </div>
        ) : null}
        {userPresaleData && userPresaleData.isReferred ? (
          <PresaleDialog
            trigger={
              <Button
                variant={"dialog"}
                className="border-none font-black lg:text-lg lg:rounded-2xl lg:h-14 rounded-md lg:px-14"
              >
                Join Presale
              </Button>
            }
          />
        ) : (
          <ConnectWallet>
            <Button className="border-none font-black lg:text-lg lg:rounded-2xl lg:h-14 rounded-md lg:px-14">
              Connect Wallet
            </Button>
          </ConnectWallet>
        )}
      </div>
    </div>
  );
}
