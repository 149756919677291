import { TransactionResponse } from "ethers";
import BigNumber from "bignumber.js";
import { getContractCall } from "./";
import { BIG_TEN } from "@/constants";
import { getBep20Contract } from "../contracts";
import { getMDappAddress } from "@/utils/address";
import { useWeb3Context } from "@/context/Web3Context";

interface TransferTokenProps {
  address: string;
}

export function useTokenTransferCallback(): {
  transferToken: (props: TransferTokenProps) => Promise<TransactionResponse>;
} {
  const { contractCall } = getContractCall();
  const { userSigner } = useWeb3Context();

  const mDappTokenContract = getBep20Contract(getMDappAddress(), userSigner);

  const transferToken = async (
    props: TransferTokenProps
  ): Promise<TransactionResponse> => {
    const airdropAmount = 100;
    const amount = new BigNumber(airdropAmount)
      .times(BIG_TEN.pow(18))
      .toString(10);

    if (!mDappTokenContract) {
      console.error("mdappContract is null");
      return;
    }

    const functionName = "transfer";

    try {
      const estimatedGas = await mDappTokenContract[functionName].estimateGas(props.address, amount);

      const response = await contractCall(mDappTokenContract, functionName, [props.address, amount], {
        gasLimit: estimatedGas,
      });

      return response;
    } catch (error: any) {
      console.error("Error: ", error);

      if (error.reason) {
        throw error.reason;
      }

      if (error.code && error.code === "CALL_EXCEPTION") {
        // eslint-disable-next-line no-throw-literal
        throw "Please verify you have enough funds to cover gas and transaction fee and try again";
      }

      throw error;
    }
  };

  return { transferToken };
}
