import copy from "@/assets/images/sidebar/copy.svg";
import controller from "@/assets/images/sidebar/controller.svg";
import { Button } from "@/components/ui/button";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { useWeb3Context } from "@/context/Web3Context";
import { WithdrawDialog } from "@/components/dialogs/withdraw";
import withdrawArrowUp from "@/assets/images/sidebar/withdraw-arrow-up.svg";
import { getFormattedBalance, getFullDisplayBalance } from "@/helpers/formats";
import fadedSloth from "@/assets/images/sloth-faded.png";

export function Sidebar() {
  const { address, referralLink, userPresaleData } = useWeb3Context();

  let userCoinBalance = "0";

  if (userPresaleData && userPresaleData.balance) {
    userCoinBalance = getFormattedBalance(
      getFullDisplayBalance(userPresaleData.balance)
    );
  }

  return (
    <div className="lg:h-screen w-full lg:min-w-[320px] lg:max-w-[320px] bg-white flex flex-col space-y-10 py-10 px-5 overflow-y-scroll">
      <div className="flex justify-between items-center">
        <div className="w-6 h-6 relative hidden lg:block"></div>

        <div className="flex space-x-2 items-center">
          {address && (
            <span>
              {address.length > 15 ? address.slice(0, 15) + "..." : address}
            </span>
          )}
        </div>
      </div>

      <div className="space-y-4 w-full">
        <h1 className="text-xl font-bold text-background">MDapp Account</h1>

        <div className="bg-[#241538] w-full h-[160px] rounded-2xl text-white flex flex-col items-center space-y-1 bg-[url('../public/wallet-mask-bg.svg')] lg:bg-none">
          <span className="font-black pt-8">MDapp Tokens</span>
          <h1 className="text-2xl font-black pb-3">{userCoinBalance}</h1>
        </div>

        {userPresaleData && userPresaleData.isReferred ? (
          <>
            <CopyToClipboard
              text={referralLink}
              onCopy={() => {
                toast.success("Copied");
              }}
            >
              <Button
                variant={"dialog"}
                size={"xxl"}
                className="border-none bg-[#241538] w-full px-3 rounded-2xl flex items-center justify-between"
              >
                Referral Link
                <img src={copy} alt="Copy Icon" />
              </Button>
            </CopyToClipboard>
            {userPresaleData.referralDebt.gt(0) ? (
              <WithdrawDialog
                trigger={
                  <Button
                    variant={"dialog"}
                    size={"xxl"}
                    className="border-none bg-[#159D12] w-full px-3 rounded-2xl flex items-center justify-between"
                  >
                    <span className="font-black">Cash out earnings</span>
                    <img src={withdrawArrowUp} alt="Icon" />
                  </Button>
                }
              />
            ) : null}
          </>
        ) : null}

        <div className="w-full h-[115px] rounded-3xl shadow-lg p-5 flex justify-between flex-col">
          <div className="flex items-center justify-between">
            <img src="/logo-dark.svg" alt="" />
            <img src={fadedSloth} alt="" className="w-10 h-10" />
          </div>
          <h1 className="text-background font-black">
            {" "}
            Launchpad Coming Soon!
          </h1>
        </div>

        <div className="w-full h-[115px] rounded-3xl shadow-lg p-5 flex justify-between flex-col">
          <div className="flex items-center justify-between">
            <img src="/logo-dark.svg" alt="" />
            <img src={controller} alt="" className="w-9 h-9" />
          </div>
          <h1 className="text-background "> Play To Earn Coming Soon!</h1>
        </div>
      </div>
    </div>
  );
}
