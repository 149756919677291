const targetGoals = [
  {
    date: "Jan - Mar 2024",
    title: "PHASE I",
    content:
      "Start the development of user-friendly platform with core meme functionalities Expand community through social media & targeted outreach Finalize $MDapp tokenomics & whitepaper",
  },
  {
    date: "April - June 2024",
    title: "PHASE II",
    content:
      "Presale & Token Launch (DEX) in May Aggressive marketing blitz with influencers & community champions Secure partnerships & generate pre-launch hype",
  },
  {
    date: "July - Sep 2024",
    title: "PHASE III",
    content:
      "Launch secure NFT marketplace for memes Introduce advanced meme creation tools & enhanced social features Empower $MDapp holders with community governance programs",
  },
  {
    date: "Oct - Dec 2024",
    title: "PHASE IV",
    content:
      "Prototype VR/AR integration & play-to-earn games Integrate fiat on-ramp for wider accessibility Continuously improve platform & embrace new technologies Beyond 2024 Expand ecosystem & foster collaborations Real-world partnerships for social impact & creator monetization Stay ahead of the curve with cutting-edge tech",
  },
];

export function Roadmap() {
  return (
    <div
      className="sm:flex justify-center items-center flex-col px-5 md:px-10"
     
    >
      <div className="flex sm:items-center space-y-3 flex-col w-full">
        <div style={{ display: "inline-block", maxWidth: "fit-content" }}>
          <span className="px-4 py-2 rounded-full bg-secondary-nase/15 border border-secondary-base text-xs font-medium text-secondary-base bg-secondary-base/20">
            Hatching the Next Generation of Meme Tokens
          </span>
        </div>
        <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold bg-gradient-to-br from-white to-white/10  inline-block text-transparent bg-clip-text">
          Roadmap
        </h1>
      </div>
      <div className="-my-6 pt-10 pb-24 ">
        {targetGoals.map((goal, index) => (
          <TimeLine {...goal} key={index} />
        ))}
      </div>
    </div>
  );
}

function TimeLine({
  date,
  title,
  content,
}: {
  title: string;
  date: string;
  content: string;
}) {
  return (
    <div className="relative pl-8 sm:pl-80 py-6 group">
      <div className="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-40 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-40 after:w-2 after:h-2 after:bg-secondary-base after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5">
        <time className="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercae w-40 h-6 mb-3 sm:mb-0 text-secondary-base bg-primary-base/15 rounded-full">
          {date}
        </time>
        <div className="text-xl font-bold bg-gradient-to-br from-white to-white/40 inline-block text-transparent bg-clip-text">
          {title}
        </div>
      </div>

      <div className="text-white/70">{content}</div>
    </div>
  );
}
