import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { BigNumber } from "bignumber.js";
import AmountInput from "../ui/AmountInput";
import { useCustomToast } from "@/helpers/useToast";
import { useWeb3Context } from "@/context/Web3Context";
import { useEffect, useState } from "react";
import { getDecimalAmount, getFullDisplayBalance } from "@/helpers/formats";
import { useWithdrawEarningCallback } from "@/helpers/calls/useWithdrawEarningCallback";

export function WithdrawDialog({ trigger }: { trigger: React.ReactNode }) {
  const withdrawEarningCallback = useWithdrawEarningCallback();
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);
  const [errorMsg, setErrorMsg] = useState(null);
  const [pendingTx, setTxStatus] = useState<boolean>(false);
  const { txToast, errorToast, successToast } = useCustomToast();
  const { userPresaleData } = useWeb3Context();

  useEffect(() => {
    setErrorMsg(null);

    if (isNaN(withdrawalAmount)) {
      setErrorMsg("Enter a correct purchase amount");
      return;
    }

    const fullDecimalBuyAmount = getDecimalAmount(
      new BigNumber(withdrawalAmount),
      18
    );

    if (new BigNumber(userPresaleData.referralDebt).lt(fullDecimalBuyAmount)) {
      setErrorMsg("You do not have enough referral earnings.");
      return;
    }
  }, [withdrawalAmount]);

  const submit = async () => {
    if (errorMsg) {
      errorToast(errorMsg);
      return;
    }
    setTxStatus(true);
    try {
      const tx = await withdrawEarningCallback.withdrawEarnings({
        withdrawalAmount: withdrawalAmount,
      });
      successToast("Withdrawing earnings. Pending confirmation...");

      const receipt = await tx.wait();

      txToast(receipt.hash);
      setTxStatus(false);

      setTimeout(() => {
        window.location.reload();
      }, 400);
    } catch (err: any) {
      errorToast(err);
      setTxStatus(false);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            <div className="text-2xl lg:text-3xl font-bold relative flex flex-col text-center">
              <span className="relative z-10">Withdraw Earnings</span>
              <span className="dark-text-outline absolute left-1/2 transform  -translate-x-[49%] translate-y-[9%] whitespace-nowrap">
                Withdraw Earnings
              </span>
            </div>
          </DialogTitle>
          <DialogDescription className="text-center text-[10px] text-background">
            Please enter the appropriate information
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-5">
          <div className="my-2">
            Referral Earnings:{" "}
            <strong>
              {getFullDisplayBalance(userPresaleData.referralDebt, 18)} BNB
            </strong>
          </div>

          <div className="space-y-0.5 pb-2">
            <label
              htmlFor="amount"
              className="text-xs font-medium text-background"
            >
              Withdrawal Amount:
            </label>
            <div className="border-2 w-full rounded-lg p-2 border-background flex items-center">
              <AmountInput
                className="w-full outline-none border-none font-medium"
                onChange={(value) => value > 0 ? setWithdrawalAmount(value):null}
              />
              <div className="text-center flex items-center justify-center border-l-2 border-background text-[10px] pl-3 font-medium h-full">
                BNB
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2">
          {errorMsg ? <div className="jfPlkw">{errorMsg}</div> : null}
          <div className="text-left">
            <strong>Note: </strong>There's a 3% tax upon withdrawal
          </div>
        </div>
        <DialogFooter className="pt-5">
          <Button
            disabled={errorMsg || pendingTx}
            variant={"dialog"}
            size={"xxl"}
            className="w-full rounded-full"
            onClick={submit}
          >
            {pendingTx ? "Confirming..." : "Withdraw Earnings"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
