import { Button } from "@/components/ui/button";
import { BigNumber } from "bignumber.js";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import exchange from "@/assets/images/exchange.svg";
import AmountInput from "../ui/AmountInput";
import { useState, useEffect } from "react";
import { useWeb3Context } from "@/context/Web3Context";
import {
  getBalanceNumber,
  getDecimalAmount,
  getFormattedBalance,
  getFullDisplayBalance,
} from "@/helpers/formats";
import presale from "@/constants/presale";
import { useCustomToast } from "@/helpers/useToast";
import { usePurchaseTokenCallback } from "@/helpers/calls/useBuyTokenCallback";

export function PresaleDialog({ trigger }: { trigger: React.ReactNode }) {
  const purchaseTokenCallback = usePurchaseTokenCallback();
  const [purchaseAmount, setPurchaseAmount] = useState(0);
  const [errorMsg, setErrorMsg] = useState(null);
  const [pendingTx, setTxStatus] = useState<boolean>(false);
  const { txToast, errorToast, successToast } = useCustomToast();
  const { userPresaleData } = useWeb3Context();

  useEffect(() => {
    setErrorMsg(null);

    if (isNaN(purchaseAmount)) {
      setErrorMsg("Enter a correct purchase amount");
      return;
    }

    const fullDecimalBuyAmount = getDecimalAmount(
      new BigNumber(purchaseAmount),
      18
    );

    if (new BigNumber(userPresaleData.bnbBalance).lt(fullDecimalBuyAmount)) {
      setErrorMsg("Insufficient BNB");
      return;
    }

    if (
      getBalanceNumber(fullDecimalBuyAmount, 18) <
      getBalanceNumber(presale.minBuy, 0)
    ) {
      setErrorMsg(
        `You need to deposit a minimum of ${getFullDisplayBalance(
          new BigNumber(presale.minBuy),
          0
        )} BNB`
      );
      return;
    }
  }, [purchaseAmount]);

  const submit = async () => {
    if (errorMsg) {
      errorToast(errorMsg);
      return;
    }
    setTxStatus(true);
    try {
      const tx = await purchaseTokenCallback.purchaseToken({
        purchaseAmount: purchaseAmount,
      });
      successToast("Purchasing MDApp tokens. Pending confirmation...");

      const receipt = await tx.wait();

      txToast(receipt.hash);
      setTxStatus(false);

      setTimeout(() => {
        window.location.reload();
      }, 400);
    } catch (err: any) {
      errorToast(err);
      setTxStatus(false);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            <div className="text-2xl lg:text-3xl font-bold relative flex flex-col text-center">
              <span className="relative z-10">Presale Order</span>
              <span className="dark-text-outline absolute left-1/2 transform  -translate-x-[49%] translate-y-[9%] whitespace-nowrap">
                Presale Order
              </span>
            </div>
          </DialogTitle>
          <DialogDescription className="text-center text-[10px] text-background">
            Please enter the appropriate information
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-5">
          <div>
            <div className="my-2">
              Bal:{" "}
              <strong>
                {getFullDisplayBalance(userPresaleData.bnbBalance, 18)} BNB
              </strong>
            </div>
            <div className="space-y-0.5 pb-2">
              <label
                htmlFor="amount"
                className="text-xs font-medium text-background"
              >
                Purchase Amount:
              </label>
              <div className="border-2 w-full rounded-lg p-2 border-background flex items-center">
                <AmountInput
                  className="w-full outline-none border-none font-medium"
                  onChange={(value) => setPurchaseAmount(value)}
                />
                <div className="text-center flex items-center justify-center border-background text-[10px] pl-3 font-medium h-full">
                  BNB
                </div>
              </div>
            </div>

            <img
              src={exchange}
              alt="exchange icon"
              className="mx-auto"
              width={30}
              height={30}
            />

            <div className="space-y-0.5">
              <label
                htmlFor="conversion"
                className="text-xs font-medium text-background"
              >
                Expected MDapp Tokens:
              </label>
              <div className="border-2 w-full rounded-lg p-2 border-background flex items-center">
                <input
                  type="readonly"
                  value={getFormattedBalance(presale.rate * purchaseAmount)}
                  className="w-full outline-none border-none font-medium"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2">
          {errorMsg ? <div className="jfPlkw">{errorMsg}</div> : null}
          <div className="text-left">
            Min Buy: {getFullDisplayBalance(presale.minBuy, 0)} BNB
          </div>
          <div className="text-left">
            Max Buy: {getFullDisplayBalance(presale.maxBuy, 0)} BNB
          </div>
        </div>
        <DialogFooter className="pt-5">
          <Button
          disabled={errorMsg || pendingTx}
            variant={"dialog"}
            size={"xxl"}
            className="w-full rounded-full"
            onClick={submit}
          >
            {pendingTx ? "Confirming..." : "Purchase MDapp Tokens"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
