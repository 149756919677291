import { TransactionResponse } from "ethers";
import BigNumber from "bignumber.js";
import { getContractCall } from "./";
import { usePresaleContract } from "../useContract";
import { BIG_TEN } from "@/constants";

interface PurchaseTokenProps {
  purchaseAmount: number;
}

export function usePurchaseTokenCallback(): {
  purchaseToken: (props: PurchaseTokenProps) => Promise<TransactionResponse>;
} {
  const { contractCall } = getContractCall();

  const presaleContract = usePresaleContract();

  const purchaseToken = async (
    props: PurchaseTokenProps
  ): Promise<TransactionResponse> => {
    const amount = new BigNumber(props.purchaseAmount)
      .times(BIG_TEN.pow(18))
      .toString(10);

    if (!presaleContract) {
      console.error("tokenContract is null");
      return;
    }

    const functionName = "purchaseTokens";

    try {
      const estimatedGas = await presaleContract[functionName].estimateGas({
        value: amount,
      });

      const response = await contractCall(presaleContract, functionName, [], {
        value: amount,
        gasLimit: estimatedGas,
      });

      return response;
    } catch (error: any) {
      console.error("Error: ", error);

      if (error.reason) {
        throw error.reason;
      }

      if (error.code && error.code === "CALL_EXCEPTION") {
        // eslint-disable-next-line no-throw-literal
        throw "Please verify you have enough funds to cover gas and transaction fee and try again";
      }

      throw error;
    }
  };

  return { purchaseToken };
}
