import x from "@/assets/footer/x.svg";
import youtube from "@/assets/footer/youtube.svg";
import { Fragment } from "react/jsx-runtime";
import { Send } from "lucide-react";

const socialLinks = [
  {
    title: "X",
    icon: x,
    path: "https://x.com/memedapp?t=H66akWovBpGK9vO1SxAckg&s=09",
  },
  {
    title: "Telegram",
    path: "https://t.me/official_memedapp",
  },
  {
    title: "YouTube",
    icon: youtube,
    path: "https://www.youtube.com/channel/UC3to6YU1aYhphNEFf9Bevfw",
  },
];

export function Footer() {
  return (
    <Fragment>
      <div className="py-5" />
      <footer className="bg-black w-[90%] sm:w-[95%] left-0 right-0 mx-auto bg-white/10 border border-white/5 p-1 flex flex-col items-center justify-between backdrop-blur-md rounded-full">
        <div className="flex items-center justify-between w-full pl-3">
          <a
            href="https://www.memedapp.io/"
            className="flex items-center space-x-2"
          >
            <img className="w-5 h-5" src="/logo.svg" alt="" />
            <span className="text-xs font-medium">© 2024 MemeDapp.</span>
          </a>

          <div className="flex space-x-3">
            {socialLinks.map((link, index) => (
              <a
                href={link.path}
                aria-label={link.title}
                key={index}
                className="rounded-full p-2 bg-gradient-to-tr from-secondary-base to-primary-base flex items-center justify-center"
              >
                {link.icon ? (
                  <img src={link.icon} alt="" className="w-5 h-5" />
                ) : (
                  <Send className="w-5 h-5 stroke-black" strokeWidth={1.5}/>
                )}
              </a>
            ))}
          </div>
        </div>
      </footer>
      <div className="py-5" />
    </Fragment>
  );
}
