import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IAirdropData } from "../../../types";
import Http from "../../../helpers/http";
import LoadingAnimation from "@/components/ui/PageLoader";
import { onSignMessage } from "@/helpers/user";
import { useWeb3Context } from "@/context/Web3Context";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { useCustomToast } from "@/helpers/useToast";
import { useTokenTransferCallback } from "@/helpers/calls/useTokenTransferCallback";

interface Props {
  page: number;
  setPage: (by: number) => void;
  limit: number;
  setLimit: (by: number) => void;
  setTotal: (by: number) => void;
}

function UsersTable(props: Props) {
  const { limit, page, setTotal } = props;
  const { walletProvider } = useWeb3ModalProvider();
  const { address } = useWeb3Context();
  const tokenTransferCallbacks = useTokenTransferCallback();
  const { txToast, errorToast, successToast } = useCustomToast();
  const [data, setData] = useState<IAirdropData[]>([]);
  const [isLoading, setLoader] = useState(true);

  const getAirdropers = async () => {
    const url = "/users/airdrops";
    const result: any = await new Http().get(url, { page, page_size: limit });
    setData(result.result);
    setTotal(result.pagination.count);
    setLoader(false);
  };

  const onReleaseAirdrop = async (airdropUser: IAirdropData) => {
    if (!address || !walletProvider) {
      return;
    }

    const response = await onSignMessage(walletProvider);
    if (!response) return;

    const payload = {
      signature: response.signature,
      message: response.message,
      address: address,
      airdrop_pk: airdropUser.id,
    };

    try {
      const url = `/users/verify-airdrop`;
      await new Http().post(url, payload);

      const tx = await tokenTransferCallbacks.transferToken({address: airdropUser.public_address});
      successToast("Releasing airdrop. Pending confirmation...");

      const receipt = await tx.wait();

      txToast(receipt.hash);

      setTimeout(() => {
        window.location.reload();
      }, 600);
    } catch (err: any) {
      errorToast(err);
    }
  };

  useEffect(() => {
    getAirdropers();
  }, [page, limit]);

  return (
    <LoadingAnimation loading={isLoading} dataLength={data?.length}>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Address</Th>
              <Th>Twitter Handle</Th>
              <Th>Email Address</Th>
              <Th>Telegram Handle</Th>
              <Th>Airdrop Released</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((item: IAirdropData, index: number) => {
              return (
                <Tr fontSize={"14px"} key={index}>
                  <Td>
                    {item.public_address.length > 15
                      ? item.public_address.slice(0, 15) + "..."
                      : item.public_address}
                  </Td>
                  <Td>{item.twitter_handle}</Td>
                  <Td>{item.email_address}</Td>
                  <Td>{item.telegram_handle}</Td>
                  <Td>{item.is_verified ? "Yes" : "No"}</Td>
                  <Td>
                    {!item.is_verified ? (
                      <Button
                        onClick={() => {
                          onReleaseAirdrop(item);
                        }}
                        h={"40px"}
                        gap={"2"}
                        rounded={"5px"}
                        px={"7"}
                        bgColor={"#1F7CFF"}
                        _hover={{ backgroundColor: "#1F7CFF" }}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        color={"white"}
                      >
                        Release Airdrop
                      </Button>
                    ) : null}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </LoadingAnimation>
  );
}

export default UsersTable;
