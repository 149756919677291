import { multicallProvider } from "@/utils/web3";
import { getBep20Contract, getPresaleContract } from "../contracts";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { simpleRpcProvider } from "@/utils/web3";
import { getPresaleAddress, getMDappAddress } from "@/utils/address";
import { SerializedDownline } from "@/types";

export const fetchUserDetails = async (account) => {
  const presaleContract = getPresaleContract(
    getPresaleAddress(),
    multicallProvider
  );
  const [
    referrer,
    referralEarnings,
    totalDirectReferrals,
    totalPurchase,
    totalPurchaseValue,
    referralDebt,
    totalWithdrawnEarnings,
    leadershipScore,
    totalReferralEarnings
  ] = await presaleContract.users(account);

  return {
    isReferred: referrer !== ethers.ZeroAddress,
    referralEarnings: new BigNumber(referralEarnings.toString()),
    totalReferrals: new BigNumber(totalDirectReferrals.toString()).toJSON(),
    totalPurchase: new BigNumber(totalPurchase.toString()),
    totalPurchaseValue: new BigNumber(totalPurchaseValue.toString()),
    referralDebt: new BigNumber(referralDebt.toString()),
    totalWithdrawnEarnings: new BigNumber(totalWithdrawnEarnings.toString()),
    leadershipScore: new BigNumber(leadershipScore.toString()),
    totalReferralEarnings: new BigNumber(totalReferralEarnings.toString()),
  };
};

export const fetchBalance = async (account: string) => {
  const provider = simpleRpcProvider;
  const balance = await provider.getBalance(account);

  return new BigNumber(balance.toString());
};

export const getMDappBalance = async (account: string) => {
  const mDappTokenContract = getBep20Contract(getMDappAddress());
  const userBalance = await mDappTokenContract.balanceOf(account);

  return new BigNumber(userBalance.toString());
};

export const getContractBalance = async () => {
  const contractAddress = getPresaleAddress();

  const balance = getMDappBalance(contractAddress);
  return balance;
}

export const generalUserData = async (account) => {
  const mDappTokenContract = getBep20Contract(getMDappAddress());
  const [userBalance, userBNBBalance, userDetails] = await Promise.all([
    mDappTokenContract.balanceOf(account),
    fetchBalance(account),
    fetchUserDetails(account),
  ]);

  const userData = {
    balance: new BigNumber(userBalance.toString()),
    bnbBalance: userBNBBalance,
    ...userDetails,
  };

  return userData;
};

export const getUserDownlines = async (account: string, level: number) => {
  const presaleContract = getPresaleContract(getPresaleAddress());
  const downlines = await presaleContract.getUserDownlines(account, level);

  const referralDowlines: SerializedDownline[] = downlines.map((downline) => {
    return {
      address: downline,
      amount: null,
    };
  });

  return referralDowlines;
};
