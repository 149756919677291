import ReactDOM from "react-dom/client";
import { ChakraProvider } from '@chakra-ui/react';
import "./index.css";
import App from "./App";


// Render the app
const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<ChakraProvider>
    <App />
  </ChakraProvider>);
}
